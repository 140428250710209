import React, { useState } from 'react'
import HelmetWrapper from '../../../Config/HelmetWrapper';
import PlanCard from './PlanCard';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';

function SettingDash() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  return (
    <div>
    <HelmetWrapper />
    <Header toggleSidebar={toggleSidebar} />
    <div className='flex flex-row'>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <PlanCard />
    </div>
    {/* <Main/> */}
  </div>
  )
}

export default SettingDash
