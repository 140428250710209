import React from "react";
import HomePage from "./components/landingPage/HomePage";
import Scaling from "./components/download/Scaling";
import SearchBar from "./components/FAQs/SearchBar.jsx";
import { ThemeProvider } from "../src/layouts/ThemeContext.js";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./Layout";
import ContactUS from "./components/support/ContactUS.jsx";
import Coming from "./components/comingsoon/Coming.jsx";
import Login from "./Admin/Auth/Login.jsx";
import LoginScreen from "./auth/Login.js";
import SignupScreen from "./auth/Register.js";
import Dashboard from "./Admin/Screens/Dashboard.jsx";
import UserDashboard from "./components/Dashboard/Home/Dashboard.js"
import ProtectedRoute from "./auth/ProtectedRoute.js";
import UserProtectedRoute from "./components/Dashboard/ProtectedRoute/ProtectedRoute.js";
import News from "./components/News/News.jsx";
import HomeScreen from "./Admin/Screens/Images/HomeScreen.jsx";
import DownloadScreen from "./Admin/Screens/Images/DownloadScreen.jsx";
import FeatureSection from "./Admin/Screens/Sections/FeatureSection.js";
import FAQSection from "./Admin/Screens/FAQPage/FAQSection.js";
import AchievementSection from "./Admin/Screens/Sections/AchievementSection.js";
import PlanSection from "./Admin/Screens/Sections/PlanSection.js";
import NewsPageSection from "./Admin/Screens/NewsPage/NewsPageSection.js";
import NewsDetail from "./components/News/NewsDetail.jsx";
import SupportSection from "./Admin/Screens/Support/SupportSection.js";
import HomePageContent from "./Admin/Screens/Content/Sections/HomePageContent.js";
import DownloadPageContent from "./Admin/Screens/Content/DownloadPageContent.js";
import PrivacyPageContent from "./Admin/Screens/Privacy/PrivacyPageContent.js";
import PrivacyPolicy from "./components/Privacy/PrivacyPolicy.js";
import TermPage from "./components/Term/TermPage.js";
import SEOScreen from "./Admin/Screens/SEOPage/SEOScreen.jsx";
import { SEOProvider } from "./Config/SEOContext.js";
import OptionSidebar from "./Admin/Screens/Newpage/OptionSidebar.js";
import DndContext from "./layouts/DndContext.js";
import { PageProvider } from "./layouts/PageContext.js";
import { RowColumnProvider } from "./layouts/RowColumnContext.js";
import PreviewScreen from "./Admin/Screens/Newpage/Preview/PreviewScreen.js";
import PageList from "./Admin/Screens/Newpage/List/PageList.js";
import { FileMapProvider } from "./Context/FileMapContext.js";
import Whyus from "./components/Whyus/Whyus.jsx";
import Verify from "./auth/Verify.js";
import Checkout from "./auth/Checkout.js";
import SettingDash from "./components/Dashboard/Settings/SettingDash.js";
import Settings from "./components/Dashboard/Profile/Settings.js";
import { DataProvider, HeaderContext } from "./Context/HeaderContext.js";
import { FooterProvider } from "./Context/FooterContext.js";
import TermPageContent from "./Admin/Screens/Term/TermPageContent.js";
import FooterSection from "./Admin/Screens/Sections/FooterSection.js";
import StatSectionContent from "./Admin/Screens/Sections/StatSectionContent.js";
import HeaderSection from "./Admin/Screens/Sections/HeaderSection.js";
import Newsletter from "./Admin/Screens/Newsletter/Newsletter.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/home",
    element: <HomePage />,
  },
  {
    path: "/download",
    element: <Scaling />,
  },
  {
    path: "/coming",
    element: <Coming />,
  },
  {
    path: "/faqs",
    element: <SearchBar />,
  },
  {
    path: "/news-article",
    element: <News />,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/term",
    element: <TermPage />,
  },
  {
    path: "/login",
    element: <LoginScreen />,
  },
  {
    path: "/news/:slug",
    element: <NewsDetail />,
  },

  {
    path: "/support",
    element: <ContactUS />,
  },
  {
    path: "/admin/login",
    element: <Login />,
  },
  // {
  //   path: "/admin/signup",
  //   element: <Signup />,
  // },
  {
    path: "/signup",
    element: <SignupScreen />
  },
  {
    path: "/admin/dashboard",
    element: <ProtectedRoute element={<Dashboard />} />,
  },
  {
    path: "/admin/home",
    element: <ProtectedRoute element={<HomeScreen />} />,
  },
  {
    path: "/admin/download",
    element: <ProtectedRoute element={<DownloadScreen />} />,
  },
  {
    path: "/admin/feature",
    element: <ProtectedRoute element={<FeatureSection />} />,
  },
  {
    path: "/admin/achievements",
    element: <ProtectedRoute element={<AchievementSection />} />,
  },
  {
    path: "/admin/plans",
    element: <ProtectedRoute element={<PlanSection />} />,
  },
  {
    path: "/admin/faqs",
    element: <ProtectedRoute element={<FAQSection />} />,
  },
  {
    path: "/admin/faqs",
    element: <ProtectedRoute element={<FAQSection />} />,
  },
  {
    path: "/admin/news",
    element: <ProtectedRoute element={<NewsPageSection />} />,
  },
  {
    path: "/admin/support",
    element: <ProtectedRoute element={<SupportSection />} />,
  },
  {
    path: "/admin/newsletter",
    element: <ProtectedRoute element={<Newsletter />} />,
  },
  {
    path: "/admin/content",
    element: <ProtectedRoute element={<HomePageContent />} />,
  },
  {
    path: "/admin/footer",
    element: <ProtectedRoute element={<FooterSection />} />,
  },
  {
    path: "/admin/header",
    element: <ProtectedRoute element={<HeaderSection />} />
  },
  {
    path: "/admin/privacy",
    element: <ProtectedRoute element={<PrivacyPageContent />} />,
  },
  {
    path: "/admin/term",
    element: <ProtectedRoute element={<TermPageContent />} />,
  },
  {
    path: "/admin/stat",
    element: <ProtectedRoute element={<StatSectionContent />} />,
  },
  {
    path: "/admin/downloadsection",
    element: <ProtectedRoute element={<DownloadPageContent />} />,
  },
  {
    path: "/admin/seo",
    element: <ProtectedRoute element={<SEOScreen />} />,
  },
  {
    path: "/admin/newpage/:slug?",
    element: <ProtectedRoute element={<OptionSidebar />} />,
  },
  {
    path: "/admin/listpage",
    element: <ProtectedRoute element={<PageList />} />,
  },
  {
    path: "/:slug",
    element: <PreviewScreen />,
  },
  {
    path: "/whyus",
    element: <Whyus />,
  },
  {
    path: "/user/dashboard",
    element: <UserProtectedRoute element={< UserDashboard />} />,
  },
  {
    path: "/user/verify",
    element: <Verify />,
  },
  {
    path: "/user/checkout",
    element: <Checkout />,
  },
  {
    path: "/user/profile",
    element: <UserProtectedRoute element={<Settings />} />,
  },
  {
    path: "/user/setting",
    element: <UserProtectedRoute element={<SettingDash />} />,
  },
]);

function App() {
  return (
    <FooterProvider>
      <DataProvider>
        <FileMapProvider>
          <RowColumnProvider>
            <DndContext>
              <SEOProvider>
                <ThemeProvider>
                  <PageProvider>
                    <div>
                      <div
                        className="d-flex flex-column flex-root app-root"
                        id="kt_app_root"
                      >
                        <div
                          className="app-page  flex-column flex-column-fluid "
                          id="kt_app_page"
                        >
                          <Layout>
                            <RouterProvider router={router} />
                          </Layout>
                        </div>
                      </div>
                    </div>
                  </PageProvider>
                </ThemeProvider>
              </SEOProvider>
            </DndContext>
          </RowColumnProvider>
        </FileMapProvider>
      </DataProvider>
    </FooterProvider>
  );
}

export default App;
