import React from 'react'

function TextAlignment({ formData, selectedElement, setFormData, setAlign }) {
  const onAlignChange = (event) => {
    const alignment = event.currentTarget.dataset.align;
    setAlign(alignment);

    if (
      selectedElement.rowIndex !== null &&
      selectedElement.columnIndex !== null &&
      selectedElement.itemIndex !== null
    ) {
      const rows = [...formData.rows];
      const column = rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];
      let item

      if (column.data) {
        const columnData = JSON.parse(column.data)
        item = columnData.items[selectedElement.itemIndex] || {}
        item.style = item.style || {}
        item.style.alignment = alignment
        column.data = JSON.stringify({ ...columnData, items: columnData.items })
      } else {
        item = column.items[selectedElement.itemIndex] || {}
        item.style = item.style || {}
        item.style.alignment = alignment
      }

      setFormData({ ...formData, rows });
    } else {
      console.error('Selected element indices are not set correctly.');
    }
  };
  return (
    <>
      <h3 className='my-5 font-semibold'>Text Alignment</h3>
      <div className='flex justify-between w-full'>
        <button className='p-2 m-2 rounded shadow-sm w-full' onClick={onAlignChange} data-align='left'><i className="fa-regular fa-align-left"></i></button>
        <button className='p-2 m-2 rounded shadow-sm w-full' onClick={onAlignChange} data-align='center'><i className="fa-solid fa-align-center"></i></button>
        <button className='p-2 m-2 rounded shadow-sm w-full' onClick={onAlignChange} data-align='right'><i className="fa-solid fa-align-right"></i></button>
      </div>
    </>
  )
}

export default TextAlignment
