import React from 'react'

const MarginInput = ({ side, value, onChange }) => (
    <input
        type='number'
        placeholder='0'
        className='w-full outline-none p-2 m-2 rounded shadow-sm'
        onChange={onChange}
        data-side={side}
        value={value}
    />
);
const MarginInputs = ({ margin, onMarginChange }) => {
    const sides = ['top', 'right', 'bottom', 'left'];

    return (
        <>
            {sides.map(side => (
                <MarginInput
                    key={side}
                    side={side}
                    value={margin[side]}
                    onChange={onMarginChange}
                />
            ))}
        </>
    );
};


export default MarginInputs
