import React, { useContext, useEffect, useState } from 'react'
import Header from '../../layouts/Header'
import Footer from '../../layouts/Footer'
import { ThemeContext } from '../../layouts/ThemeContext';
import AnimatedText from '../../layouts/AnimatedText';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Helpers from '../../Config/Helpers';
import Carousel from '../landingPage/Carousel';
class ScrollToTopOnMount extends React.Component {
    componentDidMount() {
      window.scrollTo(0, 0);
    }
  
    render() {
      return null;
    }
  }
function Whyus() {
    const { isDarkMode } = useContext(ThemeContext);
    useEffect(() => {
        document.title = "Why Choose us | ClockIn";
    })
    const [achievements, setAchievement] = useState([])
    const getAchievements = async () => {
        try {
            const response = await axios.get(`${Helpers.apiUrl}achievements/show`);
            setAchievement(response.data.data);
            // console.log('ache', response);
        } catch (error) {
            console.log("error in fetching data", error);
        }
    };
    
    useEffect(() => {
        getAchievements();
    }, []);
    return (
        <>
        <ScrollToTopOnMount />
            <div>
                <div
                    className='bg-cover bg-center bg-no-repeat h-[37vh] lg:h-[50vh] w-full'
                    style={{ backgroundImage: `url(${isDarkMode ? 'assets/bg1.png' : 'assets/bg2.png'})` }}
                >
                    <Header />
                    <div className='py-5 mt-10'>
                        <h1 className='text-text font-semibold text-3xl pt-8 text-center'>Why Choose Us</h1>
                    </div>
                </div>

                <AnimatedText>
                    <div className="bg-pinkbackground">
                        <div className="flex flex-col lg:flex-row justify-between items-center px-8 md:px-16 lg:px-[160px] py-8 container mx-auto pt-5  w-full">
                            <div className="text-center lg:text-left lg:order-1 order-2">
                                <h2 className="text-text font-bold lg:text-4xl text-2xl mb-4 lg:mb-8">Efficiency Perfected with Clockin
                                </h2>
                                <p className="text-text mb-4 md:mb-8">Time tracking redefined. Clockin helps businesses optimize productivity with precision and ease. Our premium solution is designed to deliver unparalleled efficiency and personalized tracking tailored to your needs.
                                </p>
                                <Link to="/download" className="mt-4 md:mt-8 lg:mt-12 bg-[#FF7A50] hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-xl transition duration-300">Try Clockin Now
                                </Link>
                            </div>
                            <img
                                src={"/assets/f7.png"
                                }
                                alt="whyus"
                                className="mt-8 lg:mt-0 max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg lg:order-2 order-1"
                            />
                        </div>
                    </div>
                </AnimatedText>
                <div className='bg-achbackground'>
                    <div className=" pb-12 pt-3  container mx-auto px-0">
                        <div className=''>
                            <h2 className='text-center text-orange-600 text-2xl pt-12 font-semibold '>Our Achievements From Clients</h2>
                            <div className='flex justify-center'>

                                <p className='text-center text-gray-400 pt-6 text-lg p-5 lg:w-1/2 sm:w-full'>Optimized productivity, streamlined tasks and improved efficiency with our innovative time tracker software.</p>
                            </div>
                        </div>
                        <Carousel achievements={achievements} />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Whyus
