import React from 'react'

function BoxShadow({formData , selectedElement , setFormData }) {
    const handleBoxShadowChange = (event) => {
        const { value } = event.target;
    
        if (
          selectedElement.rowIndex !== null &&
          selectedElement.columnIndex !== null &&
          selectedElement.itemIndex !== null
        ) {
          const rows = [...formData.rows];
          const column = rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];
    
          let item;
          if (column.data) {
            const columnData = JSON.parse(column.data);
            item = columnData.items[selectedElement.itemIndex];
            item.style = item.style || {};
    
            item.style.boxShadow = value;
    
            column.data = JSON.stringify({ ...columnData, items: columnData.items });
          } else {
            item = column.items[selectedElement.itemIndex];
            item.style = item.style || {};
    
            item.style.boxShadow = value;
          }
    
          setFormData({ ...formData, rows });
        } else {
          console.error('Selected element indices are not set correctly.');
        }
      };
  return (
    <>
      <h3 className='mt-5 font-semibold'>Select a Box Shadow</h3>
          <select onChange={handleBoxShadowChange} className='px-5 py-2 w-full mt-4 outline-none rounded shadow-sm'>
            <option value="none">None</option>
            <option value="0px 4px 6px  rgba(0, 0, 0, 0.1)">Small</option>
            <option value="0px 8px 15px rgba(0, 0, 0, 0.2)">Medium</option>
            <option value="0px 12px 20px rgba(0, 0, 0, 0.3)">Large</option>
          </select>
    </>
  )
}

export default BoxShadow
