import React from 'react'

function BorderRadius({onRadiusChange ,item}) {
  return (
    <>
      <div className='flex justify-between w-full mt-5'>
            <div className='w-full'>
              <label className='font-semibold'>Border Radius : </label>
              <input
                type='number'
                className='px-5 py-2 mt-2 rounded shadow-sm w-[100%] outline-none'
                placeholder='0px'
                onChange={onRadiusChange}
                value={item.style.borderRadius || 0}
              />

            </div>
          </div>
    </>
  )
}

export default BorderRadius
