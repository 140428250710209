import React from 'react'
import PaddingInputs from './Inputs/PaddingInputs';

function Padding({formData , selectedElement , setFormData , setPadding , padding}) {
    const onPaddingChange = (event) => {
        const { value, dataset } = event.target;
        const side = dataset.side;
    
        setPadding((prevPadding) => ({
          ...prevPadding,
          [side]: parseInt(value, 10) || 0,
        }));
    
        if (
          selectedElement.rowIndex !== null &&
          selectedElement.columnIndex !== null &&
          selectedElement.itemIndex !== null
        ) {
          const rows = [...formData.rows];
          const column = rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];
    
          let item;
    
          if (column.data) {
            const columnData = JSON.parse(column.data);
            item = columnData.items[selectedElement.itemIndex];
            item.style = item.style || {};
    
            item.style.padding = {
              ...item.style.padding,
              [side]: parseInt(value, 10) || 0,
            };
    
            column.data = JSON.stringify({ ...columnData, items: columnData.items });
          } else {
            item = column.items[selectedElement.itemIndex];
            item.style = item.style || {};
            item.style.padding = {
              ...item.style.padding,
              [side]: parseInt(value, 10) || 0,
            };
          }
    
          setFormData({ ...formData, rows });
        } else {
          console.error('Selected element indices are not set correctly.');
        }
      };
  return (
    <>
      <h3 className='mt-5 font-semibold'>Padding</h3>
          <div className='flex justify-between w-full'>
            <PaddingInputs padding={padding} onPaddingChange={onPaddingChange}/>
          </div>
    </>
  )
}

export default Padding
