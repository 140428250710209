import React, { useContext } from 'react'
import { ThemeContext } from '../../../../layouts/ThemeContext'

function Heading({handleFeatureChange , subItem}) {
    
    const {isDarkMode} = useContext(ThemeContext)
    return (
        <>
            <input
                type="text"
                autoComplete="off"
                className={`w-full h-full outline-none ${subItem.style.headingStyle}`}
                placeholder="Enter heading text"
                onChange={handleFeatureChange}
                name="content"
                value={subItem.content}
                style={{
                    color : isDarkMode ? subItem.style.color : subItem.style.lightModeColor,
                    padding: `${subItem.style.padding?.top}px ${subItem.style.padding?.right}px ${subItem.style.padding?.bottom}px ${subItem.style.padding?.left}px`,
                    margin: `${subItem.style.margin?.top}px ${subItem.style.margin?.right}px ${subItem.style.margin?.bottom}px ${subItem.style.margin?.left}px`,
                    textAlign: subItem.style.alignment,
                    background: 'none',
                    fontSize: subItem.style.fontSize
                }}
            />
        </>
    )
}

export default Heading
