import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import HelmetWrapper from '../../../Config/HelmetWrapper';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Main from './Main';
import Loader from '../../../layouts/Loader';

function Dashboard() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [loader, setLoader] = useState(true)



  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    document.title = 'User Dashboard | Clockin'
    const timer = setTimeout(() => {
      setLoader(false);
    }, 1000);
    return () => clearTimeout(timer);
  })
  return (
    <>
          <HelmetWrapper />
      {loader ? (<Loader />) : (
        <>
          <Header toggleSidebar={toggleSidebar} />
          <div className='flex flex-row'>

            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <Main />
          </div>
        </>
      )}
    </>
  )
}

export default Dashboard
