import React, { useState } from 'react'
import { Link } from 'react-router-dom';

function Header({ toggleSidebar }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  }
  const menuItems = [
    { label: 'Profile', link: '/user/profile' },
    { label: 'Settings', link: '/user/setting' },
  ];
  return (
    <>
      <div className="bg-[#222626] flex justify-between items-center">
        <a href='/'>
        <img src='/assets/logo.png' alt='logo' className='lg:w-[75%] w-[30%] p-5 ml-5' />
        </a>

        {/* Hamburger Menu for Mobile */}
        <div className="lg:hidden mr-5">
          <button onClick={toggleSidebar}>
            <i className="fa fa-bars " style={{ fontSize: "1.5rem", color: "white" }}></i>
          </button>
        </div>

        {/* User icon */}
        <div className="hidden lg:flex items-center justify-between" style={{ marginRight: '3%' }}>
          <img src='/assets/user.png' alt='user' className='w-[25px] h-[25px] cursor-pointer' onClick={toggleDropdown} />
        </div>
        {isDropdownOpen && (
          <div className="absolute right-12 mt-[9%] w-48 bg-white shadow-sm rounded-lg">
            <ul className="py-2">
              {menuItems.map((item, index) => (
                <li key={index} className="px-4 py-2 hover:bg-gray-100">
                  <Link to={item.link} className="block text-sm text-gray-700">
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Sidebar for mobile sliding menu */}
    </>
  )
}

export default Header
