import React from 'react';
import DroppableColumn from './DroppableColumn';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { usePage } from '../../../layouts/PageContext';

const RowSection = ({
  rows,
  onRemoveRow,
  handleRemoveColumn,
  onDropItem,
  headingStyle,
  heading,
  colorStyle,
  setImageRadius,
  padding,
  bgColor,
  setImageSize,
  margin,
  setMargin,
  align,
  imagesize,
  imageradius,
  selectedElement,
  setPadding,
  setSelectedElement
}) => {
  const { formData, setFormData } = usePage()
  const handleOnDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    const updatedRows = Array.from(rows);
    const [reorderedRow] = updatedRows.splice(source.index, 1);
    updatedRows.splice(destination.index, 0, reorderedRow); 
    setFormData({ ...formData, rows: updatedRows });
  };
  return (
    <div className='mt-2 flex flex-col'>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable-rows">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {rows.map((row, rowIndex) => (
                <Draggable key={row.id} draggableId={row.id.toString()} index={rowIndex}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`flex flex-row my-8 relative ${snapshot.isDragging ? 'bg-gray-100' : ''
                        }`}
                    >
                      {row.columns.map((column, index) => (
                        <DroppableColumn
                          key={index}
                          rowId={row.id}
                          onRemoveRow={onRemoveRow}
                          handleRemoveColumn={handleRemoveColumn}
                          columnIndex={index}
                          rowIndex={rowIndex}
                          item={column}
                          headingStyle={headingStyle}
                          heading={heading}
                          colorStyle={colorStyle}
                          bgColor={bgColor}
                          onDropItem={onDropItem}
                          padding={padding}
                          margin={margin}
                          align={align}
                          imagesize={imagesize}
                          setImageRadius={setImageRadius}
                          setMargin={setMargin}
                          setPadding={setPadding}
                          imageradius={imageradius}
                          selectedElement={selectedElement}
                          setSelectedElement={setSelectedElement}
                          setImageSize={setImageSize}
                        />
                      ))}
                      <button
                        className="bg-slate-200 m-1 absolute top-1 right-1 px-2 bg-opacity-75 rounded-full cursor-pointer"
                        onClick={() => onRemoveRow(row.id)}
                        style={{ transform: 'translate(-50%,-80%)' }}
                      >
                        <i className="fa-solid fa-xmark" style={{ color: 'black' }}></i>
                      </button>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

    </div>
  );
};

export default RowSection;
