import React, { useContext, useState } from 'react'
import ImageStylebar from './Styles/ImageStylebar'
import { usePage } from '../../../layouts/PageContext';
import { ThemeContext } from '../../../layouts/ThemeContext';
import Heading from './Styles/Heading';
import FontSize from './Styles/FontSize';
import Color from './Styles/Color';
import BgColor from './Styles/BgColor';
import BoxShadow from './Styles/BoxShadow';
import Padding from './Styles/Padding';
import Margin from './Styles/Margin';
import TextAlignment from './Styles/TextAlignment';
import BorderRadius from './Styles/BorderRadius';
import ButtonLinks from './Styles/ButtonLinks';

function StyleSidebar({  setColorStyle, setPadding, setMargin, setAlign, setImageSize, selectedElement, setbgColor, padding, margin, imagesize }) {
  const [headingStyle, setHeadingStyle] = useState(false)
  const { formData, setFormData } = usePage();
  const { isDarkMode } = useContext(ThemeContext)

  const handleHeadingStyle = () => {
    setHeadingStyle(!headingStyle)
  }

  const onRadiusChange = (event) => {
    const { value } = event.target;

    if (
      selectedElement.rowIndex !== null &&
      selectedElement.columnIndex !== null &&
      selectedElement.itemIndex !== null
    ) {
      const rows = [...formData.rows];
      const column = rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];

      let item;
      if (column.data) {
        const columnData = JSON.parse(column.data);
        item = columnData.items[selectedElement.itemIndex];
        item.style = item.style || {};

        item.style.borderRadius = parseInt(value, 10) || 0;

        column.data = JSON.stringify({ ...columnData, items: columnData.items });
      } else {
        item = column.items[selectedElement.itemIndex];
        item.style = item.style || {};

        item.style.borderRadius = parseInt(value, 10) || 0;
      }

      setFormData({ ...formData, rows });
    } else {
      console.error('Selected element indices are not set correctly.');
    }
  };

  const column = formData.rows[selectedElement.rowIndex]?.columns[selectedElement.columnIndex];
  let item = {};

  if (column?.data) {
    const columnData = JSON.parse(column.data);
    item = columnData.items[selectedElement.itemIndex] || {};
  } else {
    item = column?.items[selectedElement.itemIndex] || {};
  }

  item.style = item.style || {};

  return (
    <div>
      <div>
        <span className='text-md font-bold cursor-pointer' onClick={handleHeadingStyle}><i className="fa-sharp fa-solid fa-caret-down mr-3 mt-3"></i> Font Style</span>
        <>
          <Heading selectedElement={selectedElement} formData={formData} setFormData={setFormData}/>
          <FontSize selectedElement={selectedElement} formData={formData} setFormData={setFormData} item={item}/>
          <Color selectedElement={selectedElement} formData={formData} setFormData={setFormData} setColorStyle={setColorStyle} isDarkMode={isDarkMode}/>

          <BgColor selectedElement={selectedElement} formData={formData} setFormData={setFormData} setbgColor={setbgColor} isDarkMode={isDarkMode}/>

          <BoxShadow selectedElement={selectedElement} formData={formData} setFormData={setFormData} />

          <Padding selectedElement={selectedElement} formData={formData} setFormData={setFormData} setPadding={setPadding} padding={padding}/>

          <Margin selectedElement={selectedElement} formData={formData} setFormData={setFormData} setMargin={setMargin} margin={margin}/>

          <TextAlignment selectedElement={selectedElement} formData={formData} setFormData={setFormData} setAlign={setAlign}/>
          <BorderRadius onRadiusChange={onRadiusChange} item={item}/>
        </>
      </div>
      <ImageStylebar selectedElement={selectedElement} formData={formData} setFormData={setFormData} onRadiusChange={onRadiusChange} setImageSize={setImageSize}  imagesize={imagesize} />
      <ButtonLinks selectedElement={selectedElement} />
    </div>
  )
}

export default StyleSidebar