import { React, useEffect } from 'react'
import { useState } from "react";
import { usePage } from '../../../../layouts/PageContext';
import Helpers from '../../../../Config/Helpers';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import { useParams } from 'react-router-dom';
const Header = () => {
  const { formData, setFormData } = usePage();
  const [loading, setLoading] = useState(false)
  const { slug } = useParams();
  
  const handlePublish = async () => {
    setLoading(true);
  
    const createFormData = (data, formData = new FormData(), parentKey = '') => {
      if (typeof data === 'object' && data !== null && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
          createFormData(data[key], formData, parentKey ? `${parentKey}[${key}]` : key);
        });
      } else if (data instanceof File) {
        formData.append(parentKey, data);
      } else {
        formData.append(parentKey, data);
      }
      return formData;
    };
    
  
    const clonedData = JSON.parse(JSON.stringify(formData));
    const data = createFormData(clonedData);
  
    if (formData.rows && Array.isArray(formData.rows)) {
      formData.rows.forEach((row, rowIndex) => {
        if (row.columns && Array.isArray(row.columns)) {
          row.columns.forEach((column, columnIndex) => {
            if (column.items && Array.isArray(column.items)) {
              column.items.forEach((item, itemIndex) => {
                if (item.file && item.file instanceof File) {
                  data.append(`rows[${rowIndex}][columns][${columnIndex}][items][${itemIndex}][file]`, item.file);
                }
              });
            }
          });
        }
      });
    }
  
    const url = slug ? `${Helpers.apiUrl}page/update/${slug}` : `${Helpers.apiUrl}page/store`;
  
    try {
      const response = await axios.post(url, data, Helpers.authFileHeaders , Helpers.authHeaders);
      Helpers.toast("success", 'Data Stored');
      // console.log("store", response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error storing data:', error);
      Helpers.toast("error", error.response?.data?.message || "An error occurred");
    }
  };
  

  useEffect(() => {
    if (slug) {
      fetchPageData(slug);
    }
  }, [slug]);

  const fetchPageData = async (slug) => {
    try {
      setLoading(true);
      const response = await axios.get(`${Helpers.apiUrl}page/show/${slug}`);
      const pageData = response.data;
      setFormData(pageData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching page data:', error);
    }
  };
  const resetForm = () => {
    setFormData({
      name: '',
      slug: '',
      rows: [],
    })
  }
  return (
    <div>
      <div className="bg-[#222626] flex justify-between">
        <Link to='/admin/dashboard' className="btn" style={{ color: 'white' }} onClick={()=>resetForm()}>
          <i className="fa-solid fa-arrow-left"></i> Back
        </Link>
        <input
          type="text"
          placeholder="Enter Page Title"
          className="outline-none p-2 rounded-md bg-[#2e2e2e] text-center"
          style={{ color: 'white' }}
          value={formData.name || ''}
          onChange={(e) => setFormData({ ...formData, name: e.target.value, slug: e.target.value.toLowerCase().replace(/\s+/g, '-') })}
        />
        <div className="flex items-center justify-between" style={{ marginRight: '3%' }} >
          <button className="btn" style={{ color: "white", background: "green" }} onClick={handlePublish}>
            {loading ? '' : 'Publish'}
            <ClipLoader
              color="white"
              loading={loading}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header