import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import Helpers from '../Config/Helpers';

export const FooterContext = createContext();

export const FooterProvider = ({ children }) => {
  const [footer, setFooter] = useState([]);

  useEffect(() => {
    const getData = async () => {
        try {
          const response = await axios.get(`${Helpers.apiUrl}footer/show`);
          setFooter(response.data.data);
        //   console.log('res',response.data)
        } catch (error) {
          console.error('Error fetching data', error);
        }
    };

    getData();
  }, []);

  return (
    <FooterContext.Provider value={footer}>
      {children}
    </FooterContext.Provider>
  );
};
