import React from 'react'

function Heading({selectedElement , formData , setFormData}) {
    const handleChange = (event) => {
        const { value } = event.target;
    
        if (
          selectedElement.rowIndex !== null &&
          selectedElement.columnIndex !== null &&
          selectedElement.itemIndex !== null
        ) {
          const rows = [...formData.rows];
          const column = rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];
    
          let item;
          if (column.data) {
            const columnData = JSON.parse(column.data);
            item = columnData.items[selectedElement.itemIndex] || {};
            item.style = item.style || {};
            item.style.headingStyle = value;
    
            column.data = JSON.stringify({ ...columnData, items: columnData.items });
          } else {
            item = column.items[selectedElement.itemIndex] || {};
            item.style = item.style || {};
            item.style.headingStyle = value;
          }
    
          setFormData({ ...formData, rows });
        } else {
          console.error('Selected element indices are not set correctly.');
        }
      };
    return (
        <>
            <h3 className='mt-5 font-semibold'>Select a Heading</h3>
            <select className='px-5 py-2 w-full mt-4 outline-none rounded shadow-sm' onChange={handleChange}>
                <option>Select Option</option>
                <option className='h1' value='h1'>Heading 1</option>
                <option className='h2' value='h2'>Heading 2</option>
                <option className='h3' value='h3'>Heading 3</option>
            </select>
        </>
    )
}

export default Heading
