import React from 'react'

function ImageStylebar({imagesize , formData , selectedElement , setFormData , setImageSize}) {
    const onSizeChange = (event) => {
        const { value, dataset } = event.target;
        const side = dataset.side;
    
        setImageSize((prevSize) => ({
          ...prevSize,
          [side]: parseInt(value, 10) || 0,
        }));
    
        if (
          selectedElement.rowIndex !== null &&
          selectedElement.columnIndex !== null &&
          selectedElement.itemIndex !== null
        ) {
          const rows = [...formData.rows];
          const column = rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];
    
          let item;
    
          if (column.data) {
            const columnData = JSON.parse(column.data);
            item = columnData.items[selectedElement.itemIndex];
            item.style = item.style || {};
    
            if (item.type === 'image' || selectedElement.part === 'image') {
              item.style.size = {
                ...item.style.size,
                [side]: parseInt(value, 10) || 0,
              };
            } else if (item.type === 'card' && selectedElement.part === 'card') {
              item.cardStyle = item.cardStyle || {};
              item.cardStyle.size = {
                ...item.cardStyle.size,
                [side]: parseInt(value, 10) || 0,
              };
            }
    
            column.data = JSON.stringify({ ...columnData, items: columnData.items });
          } else {
            item = column.items[selectedElement.itemIndex];
            item.style = item.style || {};
    
            if (item.type === 'image' || selectedElement.part === 'image') {
              item.style.size = {
                ...item.style.size,
                [side]: parseInt(value, 10) || 0,
              };
            } else if (item.type === 'card' && selectedElement.part === 'card') {
              item.cardStyle = item.cardStyle || {};
              item.cardStyle.size = {
                ...item.cardStyle.size,
                [side]: parseInt(value, 10) || 0,
              };
            } else {
              item.style.size = {
                ...item.style.size,
                [side]: parseInt(value, 10) || 0,
              };
            }
          }
    
          setFormData({ ...formData, rows });
        } else {
          console.error('Selected element indices are not set correctly.');
        }
      };
  return (
    <div>
        <span className='text-md font-bold'><i className="fa-sharp fa-solid fa-caret-down mr-3 mt-3"></i> Image Style</span>
            <div className='flex justify-between w-full mt-5'>
                <div className='w-full'>
                    <label className='font-semibold'>Width : </label>
                    <input type='number' className='p-2 m-2 rounded shadow-sm w-[50%] outline-none' placeholder='50%' onChange={onSizeChange} data-side='width'  value={imagesize.width}/>
                </div>
                <div className='w-full'>
                    <label className='font-semibold'>Height : </label>
                    <input type='number' placeholder='50%' className='p-2 m-2 rounded shadow-sm w-[50%] outline-none' onChange={onSizeChange} data-side='height' value={imagesize.height}/>
                </div>
            </div>
    </div>
  )
}

export default ImageStylebar