import React from 'react'
import MarginInputs from './Inputs/MarginInputs';

function Margin({ formData, selectedElement, setFormData, setMargin, margin }) {
  const onMarginChange = (event) => {
    const { value, dataset } = event.target;
    const side = dataset.side;

    setMargin((prevMargin) => ({
      ...prevMargin,
      [side]: parseInt(value, 10) || 0,
    }));

    if (
      selectedElement.rowIndex !== null &&
      selectedElement.columnIndex !== null &&
      selectedElement.itemIndex !== null
    ) {
      const rows = [...formData.rows];
      const column = rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];

      let item;

      if (column.data) {
        const columnData = JSON.parse(column.data);
        item = columnData.items[selectedElement.itemIndex];
        item.style = item.style || {};

        item.style.margin = {
          ...item.style.margin,
          [side]: parseInt(value, 10) || 0,
        };

        column.data = JSON.stringify({ ...columnData, items: columnData.items });
      } else {
        item = column.items[selectedElement.itemIndex];
        item.style = item.style || {};

        item.style.margin = {
          ...item.style.margin,
          [side]: parseInt(value, 10) || 0,
        };
      }

      setFormData({ ...formData, rows });
    } else {
      console.error('Selected element indices are not set correctly.');
    }
  };

  return (
    <>
      <h3 className='mt-5 font-semibold'>Margin</h3>
      <div className='flex justify-between w-full'>
        <MarginInputs margin={margin} onMarginChange={onMarginChange} />
      </div>
    </>
  )
}

export default Margin
