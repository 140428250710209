import React, { useContext } from 'react'
import { ThemeContext } from '../../../../layouts/ThemeContext'

function Textarea({handleFeatureChange , subItem }) {
  const {isDarkMode} = useContext(ThemeContext)
  return (
    <div>
      <textarea
                placeholder="Enter Free Text Here"
                className="w-full outline-none"
                onChange={ handleFeatureChange}
                name="content"
                value={subItem.content}
                style={{
                  color : isDarkMode ? subItem.style.color : subItem.style.lightModeColor,
                  padding: `${subItem.style.padding.top}px ${subItem.style.padding.right}px ${subItem.style.padding.bottom}px ${subItem.style.padding.left}px`,
                  margin: `${subItem.style.margin.top}px ${subItem.style.margin.right}px ${subItem.style.margin.bottom}px ${subItem.style.margin.left}px`,
                  textAlign: subItem.style.alignment,
                  background: 'none',
                  fontSize: subItem.style.fontSize
                }}
              ></textarea>
    </div>
  )
}

export default Textarea
