import React from 'react'

function FontSize({formData , selectedElement , setFormData , item}) {
    const onFontChange = (event) => {
        const { value } = event.target;
    
        if (
          selectedElement.rowIndex !== null &&
          selectedElement.columnIndex !== null &&
          selectedElement.itemIndex !== null
        ) {
          const rows = [...formData.rows];
          const column = rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];
    
          let item;
          if (column.data) {
            const columnData = JSON.parse(column.data);
            item = columnData.items[selectedElement.itemIndex];
            item.style = item.style || {};
    
            item.style.fontSize = parseInt(value, 10) || 0;
    
            column.data = JSON.stringify({ ...columnData, items: columnData.items });
          } else {
            item = column.items[selectedElement.itemIndex];
            item.style = item.style || {};
    
            item.style.fontSize = parseInt(value, 10) || 0;
          }
    
          setFormData({ ...formData, rows });
        } else {
          console.error('Selected element indices are not set correctly.');
        }
      };
  return (
    <>
      <div className='flex justify-between w-full mt-5'>
            <div className='w-full'>
              <label className='font-semibold'>Font Size : </label>
              <input
                type='number'
                className='px-5 py-2 mt-2 rounded shadow-sm w-[100%] outline-none'
                placeholder='0px'
                onChange={onFontChange}
                value={item.style.fontSize || 0}
              />

            </div>
          </div>
    </>
  )
}

export default FontSize
