import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import Helpers from '../Config/Helpers';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
        try {
          const response = await axios.get(`${Helpers.apiUrl}page/show`);
          setData(response.data.data);
        //   console.log('res',response.data)
        } catch (error) {
          console.error('Error fetching data', error);
        }
    };

    getData();
  }, []);

  return (
    <DataContext.Provider value={data}>
      {children}
    </DataContext.Provider>
  );
};
