import React from 'react'

function BgColor({formData , selectedElement , setFormData , setbgColor ,isDarkMode}) {
    const onBgColorChange = (event) => {
        const { value } = event.target;
        setbgColor(value);
    
        if (
          selectedElement.rowIndex !== null &&
          selectedElement.columnIndex !== null &&
          selectedElement.itemIndex !== null
        ) {
          const rows = [...formData.rows];
          const column = rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];
          let item
    
          if (column.data) {
            const columnData = JSON.parse(column.data)
            item = columnData.items[selectedElement.itemIndex] || {}
            item.style = item.style || {}
            if (isDarkMode) {
              item.style.background = value
            } else {
              item.style.lightBackground = value
            }
            column.data = JSON.stringify({ ...columnData, items: columnData.items })
          } else {
            item = column.items[selectedElement.itemIndex] || {}
            item.style = item.style || {}
            if (isDarkMode) {
              item.style.background = value
            } else {
              item.style.lightBackground = value
            }
          }
          setFormData({ ...formData, rows });
        } else {
          console.error('Selected element indices are not set correctly.');
        }
      };
      const getCurrentBgColor = () => {
        if (
          selectedElement.rowIndex !== null &&
          formData.rows?.[selectedElement.rowIndex]?.columns?.[selectedElement.columnIndex] &&
          selectedElement.itemIndex !== null
        ) {
          const column = formData.rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];
          let item;
      
          if (column.data) {
            const columnData = JSON.parse(column.data);
            item = columnData.items[selectedElement.itemIndex] || {};
          } else {
            item = column.items[selectedElement.itemIndex] || {};
          }
      
          if (isDarkMode) {
            return item?.style?.background || '#FFFFFF'; // Default to white in dark mode
          } else {
            return item?.style?.lightBackground || '#000000'; // Default to black in light mode
          }
        }
        return isDarkMode ? '#FFFFFF' : '#000000'; // Fallback default
      };
      
  return (
    <>
      <h3 className='mt-5 font-semibold'>Select a Bg Color</h3>
      <input type='color' className='mt-4 cursor-pointer' onChange={onBgColorChange}
      value={getCurrentBgColor()}  />
    </>
  )
}

export default BgColor
