import React, { useContext, useEffect, useState } from 'react'
import Header from '../layouts/Header'
import AnimatedText from '../layouts/AnimatedText'
import Footer from '../layouts/Footer'
import { ThemeContext } from '../layouts/ThemeContext';
import Helpers from '../Config/Helpers';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

function Verify() {
    const { isDarkMode } = useContext(ThemeContext);
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        code: '',
    });

    const handleInputChange = (e) => {
        const { value, name } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const navigate = useNavigate();
    const handleSubmit = async () => {
        try {
            setLoading(true);
            const userId = localStorage.getItem('user_id');
            const dataToSend = {
                ...formData,
                id: userId
            };
            const response = await axios.post(`${Helpers.apiUrl}verify`, dataToSend);
            Helpers.setItem('user', response.data.user, true);
            Helpers.setItem('token', response.data.token);
            Helpers.toast("success", "Email verified successfully");
            setLoading(false);
            navigate('/login');
        } catch (error) {
            Helpers.toast("error", error.response.data.message);
            setLoading(false);
        }
    };
    const resend = async () => {
        try {
            setLoading(true);
            const userId = localStorage.getItem('user_id');
            const dataToSend = {
                ...formData,
                id: userId
            };
            const response = await axios.post(`${Helpers.apiUrl}resend`, dataToSend);
            Helpers.toast("success", "Code Sent to your Email");
            setLoading(false);
        } catch (error) {
            Helpers.toast("error", error.response.data.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        document.title = 'Verify | Clockin'
    }, [])
    return (
        <div>
            <div>
                <div
                    className='bg-cover bg-center bg-no-repeat h-[37vh] lg:h-[50vh] w-full'
                    style={{ backgroundImage: `url(${isDarkMode ? '/assets/bg1.png' : '/assets/bg2.png'})` }}
                >
                    <Header />
                    <div className='py-5 mt-10'>
                        <h1 className='text-text font-semibold text-3xl pt-8 text-center'>Register</h1>

                    </div>
                </div>

                <AnimatedText>
                    <div className="bg-pinkbackground m-4 rounded-3xl w-[90%] mx-auto">
                        <div className="flex flex-col lg:flex-row justify-between items-center px-8 md:px-16 lg:px-[160px] py-8 container mx-auto pt-5  w-full  ">
                            <div className="text-center lg:text-left w-[100%] lg:w-[50%] lg:order-1 order-2">
                                <div className='block mt-6 text-left' >
                                    <label className='m-3'>Enter Code :</label>
                                    <input type='number' className='p-3 m-3 rounded w-[90%] outline-none  bg-inputcolor' placeholder='Enter Verification Code' onChange={handleInputChange} name='code' /> <br />
                                    <button className=" bg-[#FF7A50] hover:bg-orange-700 text-white font-bold py-2 px-4 m-3 rounded transition duration-300 block w-[90%] text-center" onClick={handleSubmit}>{loading ? 'Loading...' : 'Verify Email'}
                                    </button>
                                    <p className='m-3 text-xs text-text'>Don't Get Code? <button className='text-[#ff7a50]' onClick={resend}>Resend Code</button></p>
                                </div>
                            </div>
                            <div className='w-[100%] lg:w-[50%] lg:order-2 order-1'>
                                <img
                                    src={"/assets/signup.png"}
                                    alt="whyus"
                                    className="mt-8 w-[70%] flex justify-center mx-auto"
                                />
                            </div>
                        </div>
                    </div>
                </AnimatedText>
                <Footer />
            </div>

        </div>
    )
}

export default Verify
