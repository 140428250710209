import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../layouts/ThemeContext';
import Header from '../layouts/Header';
import AnimatedText from '../layouts/AnimatedText';
import Footer from '../layouts/Footer';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Helpers from '../Config/Helpers';
class ScrollToTopOnMount extends React.Component {
    componentDidMount() {
      window.scrollTo(0, 0);
    }
  
    render() {
      return null;
    }
  }
function Register() {
    const { isDarkMode } = useContext(ThemeContext);
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        'name': '',
        'email': '',
        'password': ''
    })
    const handleInputChange = (e) => {
        const { value, name } = e.target
        setFormData({
            ...formData,
            [name]: value
        })
    }
    const navigate = useNavigate();
    const handleSubmit = async () => {
        try {
            setLoading(true);
            const response = await axios.post(`${Helpers.apiUrl}storeuser`, formData);
            localStorage.setItem('user_id', response.data.user.id);
            Helpers.toast("success", response.data.message);
            navigate('/user/verify');
            setLoading(false);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                Helpers.toast("error", error.response.data.message); 
            } else {
                Helpers.toast("error", "An error occurred. Please try again.");
            }
            setLoading(false);
        }
    };
    
    
    useEffect(() => {
        document.title = 'Register | Clockin'
    }, [])
    return (
        <div>
        <ScrollToTopOnMount />
            <div>
                <div
                    className='bg-cover bg-center bg-no-repeat h-[37vh] lg:h-[50vh] w-full'
                    style={{ backgroundImage: `url(${isDarkMode ? 'assets/bg1.png' : 'assets/bg2.png'})` }}
                >
                    <Header />
                    <div className='py-5 mt-10'>
                        <h1 className='text-text font-semibold text-3xl pt-8 text-center'>Register</h1>

                    </div>
                </div>

                <AnimatedText>
                    <div className="bg-pinkbackground m-4 rounded-3xl w-[90%] mx-auto">
                        <div className="flex flex-col lg:flex-row justify-between items-center px-8 md:px-16 lg:px-[160px] py-8 container mx-auto pt-5  w-full  ">
                            <div className="text-center lg:text-left w-[100%] lg:w-[50%] lg:order-1 order-2">
                                <div className='block mt-6 text-left' >
                                    <label className='m-3'>Name :</label>
                                    <input type='text' className='p-3 m-3 rounded w-[90%] outline-none  bg-inputcolor' placeholder='John Doe' onChange={handleInputChange} name='name' /> <br />
                                    <label className='m-3'>Email :</label>
                                    <input type='email' className='p-3 m-3 rounded w-[90%] outline-none  bg-inputcolor' placeholder='user@gmail.com' onChange={handleInputChange} name='email' /> <br />
                                    <label className='m-3'>Password :</label>

                                    <input type='password' className='p-3 m-3 rounded w-[90%] outline-none bg-inputcolor' placeholder='*********' onChange={handleInputChange} name='password' /><br />
                                    <label className='m-3'>Confirm Password :</label>

                                    <input type='password' className='p-3 m-3 rounded w-[90%] outline-none bg-inputcolor' placeholder='*********' onChange={handleInputChange} name='password_confirmation' />
                                    <button className=" bg-[#FF7A50] hover:bg-orange-700 text-white font-bold py-2 px-4 m-3 rounded transition duration-300 block w-[90%] text-center" onClick={handleSubmit}>{loading ? 'Please wait...' : 'Register'}
                                    </button>
                                    <p className='m-3 text-xs text-text'>Already have an Account? <Link to='/login' className='text-[#ff7a50]'>Login</Link></p>
                                </div>
                            </div>
                            <div className='lg:w-[50%] w-[100%] lg:order-2 order-1'>
                                <img
                                    src={"/assets/signup.png"}
                                    alt="whyus"
                                    className="mt-8 w-[70%] flex justify-center mx-auto"
                                />
                            </div>
                        </div>
                    </div>
                </AnimatedText>
                <Footer />
            </div>

        </div>
    )
}

export default Register
