import React, { useEffect, useState } from 'react'
import Sidebar from '../../../Components/Sidebar'
import axios from 'axios'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Helpers from '../../../../Config/Helpers'
import { Link } from 'react-router-dom';
import PublishStatusDropdown from './PublishStatusDropdown';
import Loader from '../../../../layouts/Loader';

function PageList() {
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const [paginatedData, setPaginatedData] = useState([]);
    const getData = async () => {
        setLoader(true)
        const response = await axios.get(`${Helpers.apiUrl}page/show`)
        const pages = response.data.data
        setData(response.data.data)
        setPaginatedData(Helpers.paginate(pages));
        setLoader(false)
    }
    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex);
    };
    const handleDelete = async (id) => {
        try {
            await axios.get(`${Helpers.apiUrl}page/delete/${id}` , Helpers.authHeaders);
            getData();
        } catch (error) {
            console.error('Error deleting plan', error);
        }
    };
    const MySwal = withReactContent(Swal);
    const deletePage = (id) => {
        MySwal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
            customClass: {
                confirmButton: "px-3 py-2  text-green-100 bg-red-500 rounded-lg",
                cancelButton: "px-3 py-2  text-green-100 mr-3 bg-green-500 rounded-lg"
            },
            buttonsStyling: false
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(id);
                MySwal.fire({
                    title: "Deleted!",
                    text: "Your data has been deleted.",
                    icon: "success"
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                MySwal.fire({
                    title: "Cancelled",
                    text: "Your data is safe :)",
                    icon: "error"
                });
            }
        });
    };
    useEffect(() => {
        getData()
    }, [])
    return (
        <div>


            <div id="kt_app_wrapper" className="app-wrapper flex-column flex-row-fluid">
                <Sidebar />
                {loader ?
                    <Loader />
                    :
                    <div className="card mb-5 mb-xl-8 bg-slate-200" style={{ marginTop: "-4%" }}>
                        <div className="card-header border-0 pt-5">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold fs-3 mb-1">All New Pages</span>
                            </h3>
                        </div>
                        <div className="card-body py-3 m-5 rounded bg-gray-100">
                            <div className="table-responsive">
                                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                    <thead>
                                        <tr className="fw-bold text-muted">
                                            <th className="min-w-10px">#</th>
                                            <th className="min-w-650px">Page Name</th>
                                            <th className="min-w-40px text-center">Status</th>
                                            <th className="min-w-40px text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedData[currentPage]?.filter((page) => page.is_custom === '0').map((page, index) => (
                                            <tr key={page.id}>
                                                <td>{index + 1}</td>
                                                <td className="">
                                                    <div className="d-flex flex-column w-100 me-2">
                                                        <div className="d-flex flex-start ">
                                                            <span className="text-muted me-2 fs-7 fw-bold">
                                                                <p>{page.name}</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="">
                                                    <div className="d-flex flex-column w-100 me-2">
                                                        <div className="d-flex flex-center ">
                                                            <span className="text-muted me-2 fs-7 fw-bold">
                                                                <PublishStatusDropdown data={page} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="d-flex justify-content-end flex-shrink-0">
                                                        <Link to={`/admin/newpage/${page.slug}`} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                                            <i className="fa-light fa-pencil"></i>
                                                        </Link>
                                                        <Link to={`/${page.slug}`} target='_blank' className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                                            <i className="fa-regular fa-eye"></i>
                                                        </Link>
                                                        <button onClick={() => deletePage(page.id)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                                            <i className="fa-light fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className='float-end'>
                                {paginatedData.map((_, pageIndex) => (
                                    <button
                                        key={pageIndex}
                                        onClick={() => handlePageChange(pageIndex)}
                                        style={{
                                            margin: '0 5px',
                                            padding: '5px',
                                            background: currentPage === pageIndex ? '#FF7A50' : 'gray',
                                            color: 'white'
                                        }}
                                        className='mt-4 md:mt-8 lg:mt-12 bg-[#FF7A50] hover:bg-orange-700 font-bold py-2 px-4 rounded-xl transition duration-300'
                                    >
                                        {pageIndex + 1}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default PageList