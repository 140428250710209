import React from 'react'

function Color({formData , selectedElement , setFormData , setColorStyle ,isDarkMode }) {
    const onColorChange = (event) => {
        const { value } = event.target;
        setColorStyle(value); 
    
        if (
          selectedElement.rowIndex !== null &&
          selectedElement.columnIndex !== null &&
          selectedElement.itemIndex !== null
        ) {
          const rows = [...formData.rows];
          const column = rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];
          let item;
    
          if (column.data) {
            const columnData = JSON.parse(column.data);
            item = columnData.items[selectedElement.itemIndex] || {};
            item.style = item.style || {};
            if (selectedElement.part === 'heading') {
              if (isDarkMode) {
                item.style.headingColor = value; 
              } else {
                item.style.lightModeHeading = value;
              }
            } else if (selectedElement.part === 'paragraph') {
              if (isDarkMode) {
                item.style.paragraphColor = value; 
              } else {
                item.style.lightModeParagraph = value; 
              }
            } else {
              if(isDarkMode){
                item.style.color = value; 
              }else {
                item.style.lightModeColor = value; 
              }
            }
    
            column.data = JSON.stringify({ ...columnData, items: columnData.items });
          } else {
            item = column.items[selectedElement.itemIndex] || {};
            item.style = item.style || {};
    
            if (selectedElement.part === 'heading') {
              if (isDarkMode) {
                item.style.headingColor = value; 
              } else {
                item.style.lightModeHeading = value; 
              }
            } else if (selectedElement.part === 'paragraph') {
              if (isDarkMode) {
                item.style.paragraphColor = value;
              } else {
                item.style.lightModeParagraph = value; 
              }
            } else {
              if(isDarkMode){
                item.style.color = value; 
              }else {
                item.style.lightModeColor = value; 
              }
            }
          }
    
          setFormData({ ...formData, rows }); 
        } else {
          console.error('Selected element indices are not set correctly.');
        }
      };
      const getCurrentColor = () => {
        if (
          selectedElement.rowIndex !== null &&
          formData.rows?.[selectedElement.rowIndex]?.columns?.[selectedElement.columnIndex] &&
          selectedElement.itemIndex !== null
        ) {
          const column = formData.rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];
          let item;
          if (column.data) {
            const columnData = JSON.parse(column.data);
            item = columnData.items[selectedElement.itemIndex] || {};
          } else {
            item = column.items[selectedElement.itemIndex] || {};
          }
      
          if (selectedElement.part === 'heading') {
            return isDarkMode ? item?.style?.headingColor || '#FFFFFF' : item?.style?.lightModeHeading || '#000000';
          } else if (selectedElement.part === 'paragraph') {
            return isDarkMode ? item?.style?.paragraphColor || '#FFFFFF' : item?.style?.lightModeParagraph || '#000000';
          } else {
            return isDarkMode ? item?.style?.color || '#FFFFFF' : item?.style?.lightModeColor || '#000000';
          }
        }
        return isDarkMode ? '#FFFFFF' : '#000000'; // Default fallback color
      };
      
  return (
    <>
      <h3 className='mt-5 font-semibold'>Select a Color</h3>
      <input type='color' className='mt-4 cursor-pointer ' onChange={onColorChange} 
      value={getCurrentColor()} />
    </>
  )
}

export default Color
