import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Helpers from '../../../../Config/Helpers';

const PublishStatusDropdown = ({ data }) => {
  const [isPublish, setIsPublish] = useState(data.is_publish);
  // console.log(parseInt(data.is_publish) === 1 ? 'Publish' : 'Inactive');

  // console.log(data.is_publish === 1 ? 'Publish' : 'Inactive');


  useEffect(() => {
    setIsPublish(data.is_publish);
  }, [data.is_publish]);

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value === 'Publish' ? 1 : 0;
    
    try {
      const response = await axios.post(`${Helpers.apiUrl}update-publish-status/${data.id}`, {
        is_publish: newStatus, 
      }, Helpers.authHeaders);


      if (response.status === 200) {
        setIsPublish(newStatus);
        Helpers.toast("success" , "Status Updated successfuly")
      } else {
        console.error('Failed to update status');                   
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  return (
    <select value={parseInt(isPublish) === 1 ? 'Publish' : 'Inactive'} onChange={handleStatusChange} className='outline-none p-2 rounded bg-[#f9f9f9]'>
      <option value="Publish">Publish</option>
      <option value="Inactive">Inactive</option>
    </select>
  );
};

export default PublishStatusDropdown;
