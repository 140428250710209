import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Chart from "react-apexcharts";
import Helpers from '../../../Config/Helpers';

function Main() {
  const [areaChartSeries, setAreaChartSeries] = useState([]);
  const [areaChartOptions, setAreaChartOptions] = useState({
    chart: {
      type: 'area',
      height: 350,
      toolbar: { show: false },
    },
    xaxis: {
      categories: [],
    },
    stroke: { curve: 'smooth' },
    colors: ['#FF7A50'],
    markers: { size: 5, colors: ['#FF7A50'] },
  });

  const [pieChartSeries, setPieChartSeries] = useState([]);
  const [pieChartOptions, setPieChartOptions] = useState({
    labels: [],
    legend: { position: 'bottom' },
    colors: ['#FF7A50', '#FFAA33', '#3b82f6', '#34d399'],
  });

  const [noTasks, setNoTasks] = useState(false);
  const [noProjects, setNoProjects] = useState(false);

  useEffect(() => {
    const fetchTasksCount = async () => {
      const response = await axios.get(`${Helpers.apiUrl}tasks-count?user_id=${Helpers.user_id}`);
      const taskData = response.data;

      if (taskData.length === 0) {
        setNoTasks(true);
      } else {
        setNoTasks(false);
        const dates = taskData.map((item) => item.date);
        const counts = taskData.map((item) => item.count);

        setAreaChartOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: { categories: dates },
        }));
        setAreaChartSeries([{ name: 'Tasks Created', data: counts }]);
      }
    };

    const fetchProjectStatus = async () => {
      const response = await axios.get(`${Helpers.apiUrl}projects-status?user_id=${Helpers.user_id}`);
      const projectData = response.data;

      if (projectData.length === 0) {
        setNoProjects(true);
      } else {
        setNoProjects(false);
        const labels = projectData.map((item) => item.status);
        const counts = projectData.map((item) => item.count);

        setPieChartOptions((prevOptions) => ({
          ...prevOptions,
          labels,
        }));
        setPieChartSeries(counts);
      }
    };

    fetchTasksCount();
    fetchProjectStatus();
  }, []);



  const [userData, setUserData] = useState(null);
  const [packageData, setPackageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = JSON.parse(localStorage.getItem('user'))
  const getData = async () => {
    const userId = localStorage.getItem('user_id');
    const response = axios.get(`${Helpers.apiUrl}getUserPackage/${userId}`)
      .then(response => {
        setUserData(response.data.user);
        setPackageData(response.data.package);
        setLoading(false);
      })
      .catch(error => {
        setError(error.response ? error.response.data.error : 'An error occurred');
        setLoading(false);
      });
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      <div className='lg:basis-[82%] basis-[100%] h-[89vh] overflow-auto'>
        <h1 className='text-2xl m-5 '>Welcome <span className='font-bold'>{user.name},</span></h1>
        {/* <h1 className='m-5'>Your Package</h1> */}
        <div className="container mx-auto p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-6">

            <div className="bg-white rounded-lg shadow-sm p-4 flex items-center">
              <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-300 flex items-center justify-center">
                <i className="fa-regular fa-user text-white" style={{ fontSize: "1.5rem" }}></i>
              </div>
              <div className="ml-4">
                <h3 className="text-xl font-semibold">User Name</h3>
                <p className="text-gray-600">{userData?.name || 'N/A'}</p>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-sm p-4 flex items-center">
              <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-300 flex items-center justify-center">
                <i className="fa-solid fa-dollar-sign" style={{ fontSize: "1.5rem" }}></i>
              </div>
              <div className="ml-4">
                <h3 className="text-xl font-semibold">Package Name</h3>
                <p className="text-gray-600">{packageData?.plan_name || 'N/A'}</p>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-sm p-4 flex items-center">
              <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-300 flex items-center justify-center">
                <i className="fa-solid fa-calendar" style={{ fontSize: "1.5rem" }}></i>
              </div>
              <div className="ml-4">
                <h3 className="text-xl font-semibold">Expiration Date</h3>
                <p className="text-gray-600">{userData?.trial_ends_at
                  ? new Date(userData.trial_ends_at).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                  })
                  : 'N/A'
                }
                </p>
              </div>
            </div>
            <div className="bg-white rounded-lg shadow-sm p-4 flex items-center">
              <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-300 flex items-center justify-center">
                <i className="fa-regular fa-building" style={{ fontSize: "1.5rem" }}></i>
              </div>
              <div className="ml-4">
                <h3 className="text-xl font-semibold">Role</h3>
                <p className="text-gray-600">{userData?.role || 'N/A'}
                </p>
              </div>
            </div>

            {/* Other data you need to display */}

          </div>
        </div>
        <div className="container mx-auto mt-3 px-5">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
            <div className="card col-span-1 lg:col-span-8 mb-4 shadow-sm">
              <h4 className="text-dark mb-3 font-bold ml-4 mt-3 pt-3">Task Created</h4>
              {noTasks ? (
                <>
                <img src='/assets/nothing.png' className="mx-auto w-[40%]"/>
                <p className="text-center text-gray-500 py-5 font-bold">No Task Created</p>
                </>
              ) : (
                <Chart options={areaChartOptions} series={areaChartSeries} type="area" height={300} className="px-5" />
              )}
            </div>
            <div className="card col-span-1 lg:col-span-4 mb-4 shadow-sm">
              <h4 className="text-dark mb-3 font-bold ml-4 mt-3 pt-3">Project Completion</h4>
              <div className="block w-full">
                {noProjects ? (
                  <>
                  <img src='/assets/404.png' className="mx-auto w-[80%]"/>
                  <p className="text-center text-gray-500 py-5 font-bold">No projects available</p>
                  </>
                ) : (
                  <Chart options={pieChartOptions} series={pieChartSeries} type="pie" height={300} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Main
