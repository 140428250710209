import React, { useEffect, useState } from 'react'
import Loader from '../../../layouts/Loader';
import Sidebar from '../../Components/Sidebar';
import Helpers from '../../../Config/Helpers';
import axios from 'axios';

function Newsletter() {
    const [newsletters, setnewsletter] = useState([])
    const [loader , setLoader ] = useState(false)
    const [currentPage, setCurrentPage] = useState(0);
    const [paginatedData, setPaginatedData] = useState([]);
    const getnewsletter = async () => {
        setLoader(true)
        const response = await axios.get(`${Helpers.apiUrl}newsletter/show`)
        const pages = response.data.data
        setnewsletter(response.data.data)
        setPaginatedData(Helpers.paginate(pages));
        setLoader(false)
    }

    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex);
    };
    useEffect(() => {
        getnewsletter()
    }, [])
    return (
        <div>
            <div id="kt_app_wrapper" className="app-wrapper flex-column flex-row-fluid">
                <Sidebar />
                {loader ? <Loader />
                :
                <div className="card mb-5 mb-xl-8 bg-slate-200" style={{ marginTop: "-4%" }}>
                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold fs-3 mb-1">Newsletters</span>
                        </h3>
                    </div>
                    <div className="card-body py-3 rounded m-5 bg-gray-100">
                        <div className="table-responsive">
                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>
                                    <tr className="fw-bold text-muted">
                                        <th className="min-w-10px">#</th>
                                        <th className="min-w-150px">Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedData[currentPage]?.map((newsletter, index) => (
                                        <tr key={newsletter.id}>
                                            <td>{index + 1}</td>
                                            <td>{newsletter.email}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className='float-end'>
                            {paginatedData.map((_, pageIndex) => (
                                <button
                                    key={pageIndex}
                                    onClick={() => handlePageChange(pageIndex)}
                                    style={{
                                        margin: '0 5px',
                                        padding: '5px',
                                        background: currentPage === pageIndex ? '#FF7A50' : 'gray',
                                        color: 'white'
                                    }}
                                    className='mt-4 md:mt-8 lg:mt-12 bg-[#FF7A50] hover:bg-orange-700 font-bold py-2 px-4 rounded-xl transition duration-300'
                                >
                                    {pageIndex + 1}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}

export default Newsletter
