import React, { useState, useEffect, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Helpers from '../Config/Helpers';
import Header from '../layouts/Header';
import AnimatedText from '../layouts/AnimatedText';
import Footer from '../layouts/Footer';
import { ThemeContext } from '../layouts/ThemeContext';

const stripePromise = loadStripe('pk_test_51NijEgDaCvLefk2yLQkhK1rEAK8OusAu3dySjlRVd8IDANyNoiCEcNJLePzLfZVbNDGBcogCFODrm0RrzfpSwNtb00pfh38FvV'); // Replace with your Stripe public key
class ScrollToTopOnMount extends React.Component {
    componentDidMount() {
      window.scrollTo(0, 0);
    }
  
    render() {
      return null;
    }
  }
function CheckoutForm() {
    useEffect(() => {
        document.title = 'Checkout | Clockin'
    })
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    // const [selectedPackage, setSelectedPackage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    // useEffect(() => {
    //     // Retrieve the selected package from localStorage
    //     const packageData = JSON.parse(localStorage.getItem('selectedPackage'));
    //     setSelectedPackage(packageData);
    // }, []);
    const selectedPackage = JSON.parse(localStorage.getItem('selectedPackage'));


    useEffect(() => {
        const storedPackage = JSON.parse(localStorage.getItem('selectedPackage'));
    }, []);


    const handleSubscription = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        if (!stripe || !elements) {
            return;
        }

        // Retrieve card details
        const cardElement = elements.getElement(CardElement);

        // Create a Payment Method with Stripe
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            setError(`Stripe Error: ${error.message}`);
            setLoading(false);
            return;
        }

        const user = JSON.parse(localStorage.getItem('user'));
        const userId = user.id
        // console.log('userid', userId)
        const packageId = selectedPackage.id; 

        try {
            const response = await axios.post(`${Helpers.apiUrl}create-subscription`, {
                payment_method: paymentMethod.id,
                package_id: packageId,
                user_id: userId
            });
            navigate('/user/dashboard');
        } catch (err) {
            setError('Failed to complete the subscription. Please try again.');
        }

        setLoading(false);
    };

    return (
        <form onSubmit={handleSubscription}>
            <div className="form-group mb-2">
                
                <p className='mt-3'>Activate your license by paying <span className='font-bold'> ${selectedPackage?.price || 'N/A'}</span></p>
                <div style={{ background: '#222626', borderRadius: '8px', padding: '10px' }} className='mt-5'>
                    <CardElement
                        options={{
                            style: {
                                base: {
                                    fontSize: '16px',
                                    color: '#ffffff', 
                                    '::placeholder': {
                                        color: '#999999',
                                    },
                                },
                                invalid: {
                                    color: '#fa755a', 
                                },
                            },
                        }}
                        className="form-control"
                    />
                </div>


                {error && <small className="text-danger">{error}</small>}
            </div>
            <div className="form-group mb-2">
                <button type="submit" disabled={!stripe || loading} className="bg-[#ff7a50] text-white dark:text-black font-bold py-3 px-6 mt-5 rounded-lg transition duration-300">
                    {loading ? 'Please wait...' : 'Complete Your Purchase'}
                </button>
            </div>
        </form>
    );
}

function Checkout() {
    const { isDarkMode } = useContext(ThemeContext);

    const selectedPackage = JSON.parse(localStorage.getItem('selectedPackage'));


    // useEffect(() => {
    //     const storedPackage = JSON.parse(localStorage.getItem('selectedPackage'));
    // }, []);

    return (
        <div>
        
        <ScrollToTopOnMount />
            <div className='bg-cover bg-center bg-no-repeat h-[37vh] lg:h-[50vh] w-full' style={{ backgroundImage: `url(${isDarkMode ? '/assets/bg1.png' : '/assets/bg2.png'})` }}>
                <Header />
                <div className='py-5 mt-10'>
                    <h1 className='text-text font-semibold text-3xl pt-8 text-center'>Checkout</h1>
                </div>
            </div>

            <AnimatedText>
                <div className="bg-pinkbackground m-4 rounded-3xl w-[90%] mx-auto">
                    <div className="flex flex-col lg:flex-row justify-between px-8 md:px-16 lg:px-[160px] py-8 container mx-auto pt-5 w-full">
                        <div className="text-center lg:text-left w-[100%] lg:w-[60%]">
                            <div className='block'>
                                <h2 className='text-2xl font-bold'>Package Details <span className='font-bold'> {selectedPackage?.plan_name || 'N/A'}</span></h2>
                                <h3 className='bold mt-5'>
                                    <div className='flex items-center'><i className="fa-solid fa-dollar-sign bg-[#ff7a50] p-1 rounded-3xl text-center text-xs mr-3 w-6"></i>
                                        Plan Price : <span className='font-bold'> {selectedPackage?.price || 'N/A'}</span></div>
                                </h3>
                                <h3 className='bold mt-5'>
                                    <div className='flex items-center'><i className="fa-solid fa-users bg-[#ff7a50] p-1 rounded-3xl text-center text-xs mr-3 w-6"></i>
                                        Employee Limit : <span className='font-bold'> {selectedPackage?.employees_limit || 'Unlimited'}</span></div>
                                </h3>
                                <h3 className='bold mt-5'>
                                    <div className='flex items-center'><i className="fa-solid fa-check-to-slot bg-[#ff7a50] p-1 rounded-3xl text-center text-xs mr-3 w-6"></i>
                                        Task Limit : <span className='font-bold'> {selectedPackage?.task_limit || 'Unlimited'}</span></div>
                                </h3>
                                <h3 className='bold mt-5'>
                                    <div className='flex items-center'><i className="fa-regular fa-cloud bg-[#ff7a50] p-1 text-center  rounded-3xl text-xs mr-3 w-6"></i>
                                        Type : <span className='font-bold'> {selectedPackage?.type || 'Unlimited'}</span></div>
                                </h3>
                                {/* <h3 className='mt-5'>
                                    <div className='flex items-center'><i class="fa-solid fa-info bg-[#ff7a50] p-1 rounded-3xl text-center text-xs mr-3 w-6"></i>
                                        Description : <span className='font-bold'> {selectedPackage?.description || 'Unlimited'}</span></div>
                                </h3> */}

                            </div>
                        </div>
                        <div className="text-center lg:text-left w-[100%] lg:w-[40%]">
                            <Elements stripe={stripePromise}>
                                <CheckoutForm />
                            </Elements>
                        </div>
                    </div>
                </div>
            </AnimatedText>
            <Footer />
        </div>
    );
}

export default Checkout;