import React, { useState } from 'react'
import HelmetWrapper from '../../../Config/HelmetWrapper';
import MainSetting from './MainSetting';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';

function Settings() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  return (
    <div>
      <HelmetWrapper />
      <Header toggleSidebar={toggleSidebar}/>
      <div className='flex flex-row'>
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <MainSetting />
      </div>
      {/* <Main/> */}
    </div>
  )
}

export default Settings
